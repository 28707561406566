import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './BuyOfferDialog.scss';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { I18nContext } from '../../I18n';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  offerStatusChipMap,
  getofferStatusExtraInfo,
  getOfferStatusLabel,
  READY_FOR_AUTO_CHECKOUT,
} from '../../types/enumToLabelsMap';
import { eMessageType } from '../../types/IMessageType';
import Chip from '@material-ui/core/Chip';
import { dataFormatting, offerStatusExtraInfoValues } from '../../_lib/lib';
import { PrimaryButton, TertiaryButton, TextField } from '../AtomComponents';
import Dialog from '../Dialog/Dialog';
import useDataService from '../../hooks/useDataService';
import { useDispatch, useSelector } from 'react-redux';
import { BuyOfferAlert } from './BuyOfferAlert';
import { itemaddedToCart } from '../../redux/actions/analyticsEventActions';
import StockPrice from '../StockListComponents/StockPrice';
import { hideStockListItemPrices, showSalesOptions } from '../StockListComponents/StockListUtils';
import StocklotIcon from '../StockListComponents/StockLot/StocklotIcon';
import { INetworkSystemMessage } from '../../types/INetworkSystemTypes';

const generateStyle = (isOfferDialog: boolean) =>
  makeStyles(() => ({
    paper: { maxWidth: isOfferDialog ? '600px' : '500px' },
    root: {
      '& .MuiOutlinedInput-root': {
        '& input': {
          width: '90px',
        },
      },
    },
  }))();

function BuyOfferDialog(props: any) {
  const {
    isOfferDialog,
    isOpenPopup,
    isEditMode,
    openOrClosePopup,
    item,
    makeOfferOrBuy,
    deleteOffer,
    openOrCloseAlertDialog,
    stockListSettings,
    canOffer,
  } = props;
  const classes: any = generateStyle(isOfferDialog);
  const I18n = React.useContext(I18nContext);
  const { systemMessages, networkSystemMessages } = useSelector((state: any) => ({
    systemMessages: state.systemMessageState,
    networkSystemMessages: state.networkSystemMessageState as INetworkSystemMessage,
  }));

  const [offerQuantity, setOfferQuantity] = React.useState(item.offerQuantity);
  const [offerPrice, setOfferPrice] = React.useState(item.offerPrice);
  const offerQuantityRef = useRef<any>(0);

  const { openSnackBar } = useDataService();

  const {
    minimumCheckoutQuantity,
    acceptedOrCounteredExpirationDate,
    counterPrice,
    groupDescription,
    listPrice,
    quantityAvailable,
    groupItemDescription,
    itemNumberWarehouse,
    incrementSize,
    offerStatus,
    offerState,
    itemNumber,
    specialPrice,
  } = item;

  const disableControls = offerState === READY_FOR_AUTO_CHECKOUT;

  // const { stocklistOffer } = item;
  // const { offerStatus, checkOutPrice, checkOutQuantity } = stocklistOffer;
  const dispatch = useDispatch();

  const onOfferPriceChange = (e: any) => {
    if (isNaN(e.target.value)) {
      e.preventDefault();
      return false;
    }

    setOfferPrice(parseInt(e.target.value || 0));
  };

  const onOfferQuantityChange = (e: any) => {
    if (isNaN(e.target.value)) {
      e.preventDefault();
      return false;
    }

    setOfferQuantity(parseInt(e.target.value || 0));
  };

  const onBlurOfferQuantity = (e: any, incrementSize: number, quantityAvailable: number) => {
    let numberedVal = parseInt(e.target.value || 0);
    if (numberedVal === 0) numberedVal = 1;

    let newVal = numberedVal;

    if (incrementSize > 1)
      newVal =
        numberedVal <= incrementSize
          ? incrementSize
          : Math.floor(numberedVal / incrementSize) * incrementSize;

    //newVal = newVal > quantityAvailable ? quantityAvailable : newVal;

    setOfferQuantity(newVal);
  };

  const onBlurOfferPrice = (e: any, listPrice: number) => {
    let numberedVal = parseInt(e.target.value || 0);
    if (numberedVal === 0) numberedVal = 1;

    if (numberedVal > listPrice) numberedVal = listPrice;

    setOfferPrice(numberedVal);
  };

  const onMakeOrBuyOfferClick = async () => {
    if (offerQuantity > quantityAvailable) {
      setOfferQuantity(quantityAvailable);
      openSnackBar(
        <label>
          {I18n.quantityReducedToMatchStock ||
            `Your quantity was updated, quantity reduced to match available stock`}
        </label>,
        eMessageType.warning
      );
      return;
    }
    if (!buy && offerPrice >= listPrice) {
      openSnackBar(
        <label>
          {I18n.pleasePutOfferOfLessThanListedPrice ||
            `Please put offer of less than listed price.`}
        </label>,
        eMessageType.error
      );
      return;
    }
    if (!offerPrice || offerPrice < 0) {
      openSnackBar(
        <label>{I18n.invalidOfferPrice || 'Invalid Offer Price.'}</label>,
        eMessageType.error
      );
      return;
    }
    await makeOfferOrBuy(
      isEditMode,
      item,
      {
        itemNumberWarehouse: itemNumberWarehouse,
        offerQuantity,
        offerPrice: isOfferDialog
          ? offerPrice
          : item.specialPrice
          ? item.specialPrice
          : item.listPrice,
      },
      isOfferDialog
    );
    dispatch(itemaddedToCart([item]));
  };

  const { buy, offer } = showSalesOptions(stockListSettings.categorySaleOption, item);
  return (
    <Dialog
      title={
        isEditMode
          ? isOfferDialog
            ? I18n.editOfferCart || 'Edit an Offer in Cart'
            : I18n.editBuyCart || 'Edit Buy in Cart'
          : isOfferDialog
          ? I18n.makeOfferCart || 'Make an Offer in Cart'
          : I18n._capitalize(I18n.buy) || 'Buy'
      }
      isDialogOpen={isOpenPopup}
      classes={{ paper: classes.paper }}
      fullWidth={true}
      className="px-buyoffer-dialog"
      closeDialog={() => openOrClosePopup(isOfferDialog, isEditMode, false, null)}
      content={
        <>
          <h4>
            {groupDescription}
            <StocklotIcon item={item} type="SHORT" />
          </h4>
          <label className="px-text-description capitalize">{I18n.item || 'Item'}</label>
          <p className="item-desc-header m-b-px-4">{groupItemDescription}</p>
          <p className="text-muted">{`[${itemNumber}]`}</p>
          {isEditMode && (
            <div className={'grid-x margin-bottom-1'}>
              <Chip
                label={getOfferStatusLabel(I18n, offerStatus)}
                size="small"
                className={`${offerStatusChipMap[offerStatus]} px-filter-on margin-right-1`}
                variant="outlined"
              />
              <div className="cell auto">
                {I18n._format(
                  getofferStatusExtraInfo(I18n, offerStatus),
                  offerStatusExtraInfoValues(
                    offerStatus,
                    dataFormatting('currency-with-no-decimal', offerPrice),
                    minimumCheckoutQuantity,
                    acceptedOrCounteredExpirationDate,
                    dataFormatting('currency-with-no-decimal', counterPrice),
                    I18n
                  )
                )}
              </div>
            </div>
          )}
          <div className="grid-x align-stretch">
            <div className={`cell ${isOfferDialog ? 'small-6 medium-3' : 'small-4'}`}>
              <label className="px-text-description label-header capitalize">
                {I18n.availableQty || 'Available Quantity'}
                {/*br*/}
              </label>
              <p>
                {stockListSettings['maxItemQuantityToBeDisplayed']
                  ? quantityAvailable > stockListSettings['maxItemQuantityToBeDisplayed']
                    ? `${stockListSettings['maxItemQuantityToBeDisplayed']}+`
                    : quantityAvailable
                  : quantityAvailable}
              </p>
            </div>
            <div className={`cell ${isOfferDialog ? 'small-6 medium-3' : 'small-4'}`}>
              {!hideStockListItemPrices(stockListSettings, item.category) && (
                <>
                  <label className="px-text-description label-header capitalize">
                    {I18n.listPrice || 'List Price'}
                    {/*br*/}
                  </label>
                  <p>
                    <StockPrice listPrice={listPrice} specialPrice={specialPrice} />
                  </p>
                </>
              )}
            </div>
            <div className={`cell ${isOfferDialog ? 'small-6 medium-3' : 'small-4'}`}>
              <TextField
                id="purchaseQuantity"
                autoFocus
                disabled={disableControls}
                onFocus={(e: any) => {
                  if (offerQuantityRef.current === 0) {
                    e.target.select();
                    offerQuantityRef.current++;
                  }
                }}
                label={
                  !isOfferDialog
                    ? I18n._capitalize(I18n.purchaseQty || 'Purchase Quantity')
                    : I18n._capitalize(I18n.offerQty || 'Offer Quantity')
                }
                onChange={(e: any) => onOfferQuantityChange(e)}
                variant="outlined"
                style={{ marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}
                classes={isOfferDialog ? { root: classes.root } : {}}
                value={offerQuantity}
                onBlur={(e: any) => onBlurOfferQuantity(e, incrementSize, quantityAvailable)}
              />
              <p
                className={`px-text-description text-left ${
                  parseInt(incrementSize) > 1 ? 'show' : 'hide'
                }`}
              >
                {I18n._format(I18n.incrementsOf || 'increments of ${1}', [parseInt(incrementSize)])}
              </p>
            </div>
            <div className={`cell ${isOfferDialog ? 'small-6 medium-3 offerPrice show' : 'hide'}`}>
              <TextField
                id="offerPrice"
                disabled={disableControls}
                label={I18n.offerPrice || 'Offer Price'}
                onChange={(e: any) => onOfferPriceChange(e)}
                variant="outlined"
                style={{ marginTop: '10px', marginBottom: '10px' }}
                classes={isOfferDialog ? { root: classes.root } : {}}
                value={offerPrice}
                onBlur={(e) => onBlurOfferPrice(e, specialPrice ? specialPrice : listPrice)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {dataFormatting('currency', ' ').trim()}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          {(networkSystemMessages?.[stockListSettings?.pxParticipantId]?.offerClearingSchedule
            ?.display ||
            !systemMessages.offerClearingSchedule ||
            systemMessages.offerClearingSchedule.display) && (
            <div className={`px-text-description ${isOfferDialog ? 'show' : 'hide'}`}>
              <InfoOutlinedIcon className="vertical-middle" />
              <label style={{ marginLeft: '5px' }}>
                {I18n.offerClearingSchedule ||
                  networkSystemMessages?.[stockListSettings?.pxParticipantId]?.offerClearingSchedule
                    ?.defaultMessage ||
                  systemMessages.offerClearingSchedule?.defaultMessage ||
                  I18n.infoOfferNotification ||
                  'You’ll receive a notification once offers are cleared.'}
              </label>
            </div>
          )}
          <div className="text-right margin-top-1">
            <TertiaryButton
              className="margin-right-2 uppercase"
              onClick={() => openOrClosePopup(isOfferDialog, isEditMode, false, null)}
            >
              {I18n.cancel || 'CANCEL'}
            </TertiaryButton>
            <PrimaryButton
              disabled={disableControls}
              onClick={async (e: any) => await onMakeOrBuyOfferClick()}
            >
              {isEditMode
                ? I18n.updateToCart || 'UPDATE CART'
                : !isOfferDialog
                ? I18n.addToCart || 'ADD TO CART'
                : I18n.addOfferToCart || 'ADD OFFER TO CART'}
            </PrimaryButton>
          </div>
          {buy && offer && (
            <div className="text-right margin-top-1 margin-bottom-1">
              {!canOffer && !isOfferDialog && !isEditMode ? null : (
                <>
                  <label className="lowercase">{I18n.or || 'or'}</label>
                  <TertiaryButton
                    disabled={disableControls}
                    onClick={() =>
                      !isEditMode
                        ? openOrClosePopup(!isOfferDialog, isEditMode, true, item)
                        : deleteOffer(item)
                    }
                    className="padding-right-0 px-no-bg"
                  >
                    {isEditMode
                      ? I18n.removeFromCart || 'REMOVE FROM CART'
                      : isOfferDialog
                      ? I18n.buyAtListPrice || 'BUY AT LIST PRICE'
                      : I18n.makeAnOffer || 'MAKE AN OFFER'}
                  </TertiaryButton>
                </>
              )}
            </div>
          )}
          <BuyOfferAlert
            item={item}
            itemNumberWarehouse={itemNumberWarehouse}
            openOrCloseAlertDialog={openOrCloseAlertDialog}
            stockListSettings={stockListSettings}
          />
        </>
      }
    />
  );
}
export default BuyOfferDialog;
