import apiToUrlMap, { formatString } from '../../../ApiMapping';

const isItemStockLot = (category: string) => category === 'STOCKLOT';

export const getGroupItemDescription = (item: any, isStockLotGroup?: boolean) => {
  return !!isStockLotGroup ? item.lotShortDescription : item.groupItemDescription;
};

export const isGroupStockLot = (group: any) => {
  return (
    group.items?.length === 1 &&
    group.items.reduce((acc: boolean, item: any) => {
      return acc || isItemStockLot(item.category);
    }, false)
  );
};

export const fetchStockLotsToCache = async (getQueryClientService: any, stockGroups?: any) => {
  if (!stockGroups) {
    stockGroups = await getQueryClientService({
      url: formatString(apiToUrlMap.stocksGet, {
        includeOutOfStock: true,
      }),
      queryParams: {
        httpMethod: 'GET',
      }
    });
  }

  const stockLots: any = {};
  Object.keys(stockGroups.groups).forEach((key: string) => {
    stockGroups.groups[key].items.forEach((item: any) => {
      stockLots[item.itemNumberWarehouse] = {
        lotLongDescription: item.lotLongDescription,
        lotItemInfoURL: item.lotItemInfoURL,
        lotShortDescription: item.lotShortDescription,
        group: stockGroups.groups[key].groupId,
        warehouse: stockGroups.groups[key].warehouse,
      };
    });
  });

  return stockLots;
};


export const stockLotPresent = (offers: any[], fn: any) => {
  //on hand warehouse and category is stocklot
  return offers.some(fn);
};
