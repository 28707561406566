import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

type stockLotCtx = Record<string, any>;
const StockLotSetterCtx = createContext<Dispatch<SetStateAction<any>>>(() => {});
const StockLotCtx = createContext<stockLotCtx | null>(null);
function StockLotWrapper({ children }: any) {
  const [stockLotCtx, setStockLotCtx] = useState(null);

  return (
    <StockLotSetterCtx.Provider value={setStockLotCtx}>
      <StockLotCtx.Provider value={stockLotCtx}>{children}</StockLotCtx.Provider>
    </StockLotSetterCtx.Provider>
  );
}

export const useStockLot = () => {
  return useContext(StockLotCtx);
};

export const useSetStockLot = () => {
  return useContext(StockLotSetterCtx);
};

export default StockLotWrapper;
