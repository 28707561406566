import { isAllViaPhonexAndHasExternalSystem } from '../../_lib/rules';
export const processReduxSalesOrderSettings = (settings: any) => {
  Object.keys(settings).forEach((participantId) => {
    settings[participantId].isExternalSystemManualSync = isAllViaPhonexAndHasExternalSystem(
      settings[participantId]?.salesOrderUpdateMethod
    );
    settings[participantId].singleButtonOrderFlow = !!settings[participantId].fulfillmentSettings
      .singleButtonOrderFlow;
    settings[participantId].enableOnholdForManualSyncTenant = !!settings[participantId]
      .fulfillmentSettings.enableOnholdForManualSyncTenant;
  });
  return settings;
};
