import { combineReducers } from 'redux';
import userReducer from './userReducer';
import configReducer from './configReducer';
import stockGroupReducer from './stockGroupReducer';
import urlReducer from './urlReducer';
import checkoutReducer from './checkoutReducer';
import orderComputeUpdateReducer from './orderComputeReducer';
import orderServicesReducer from './orderServicesReducer';
import systemMessageReducer from './systemMessageReducer';
import networkSystemMessageReducer from './networkSystemMessageReducer';
import analyticsEventReducer from './analyticsEventReducer';
import flagsReducer from './flagsReducer';
import pusherEventReducer from './pusherEventReducer';
import { getConfigFromLocalStorage } from '../../hooks/useFetchConfig';

const rootReducer = combineReducers({
  userState: userReducer,
  configState: configReducer,
  systemMessageState: systemMessageReducer,
  networkSystemMessageState: networkSystemMessageReducer,
  stockGroupState: stockGroupReducer,
  urlState: urlReducer,
  flagsState: flagsReducer,
  checkoutState: checkoutReducer,
  analyticsEventState: analyticsEventReducer,
  orderComputeState: orderComputeUpdateReducer,
  orderServicesState: orderServicesReducer,
  pusherEventState: pusherEventReducer,
});

export const InitialState = () => {
  let config = getConfigFromLocalStorage();
  config = config && { ...config, isLoaded: true };

  // const checkoutState = JSON.parse(localStorage.getItem('checkoutPayload') || '{}');

  const initState = {
    userState: null,
    configState: config || { isLoaded: false },
    stockGroupState: { isLoaded: false, results: null },
    systemMessageState: {},
    networkSystemMessageState: {},
    checkoutState: {},
    urlState: { url: '', preventWindowClose: null, preventRouteChange: null, preventLogout: null },
    flagsState: {},
    orderComputeState: {
      cartSubtotal: 0,
      additionalServiceFees: null,
      freight: null,
      tax: null,
      fees: null,
      cartTotalQuantity: 0,
      existingOrderSubtotal: 0,
      existingOrderTotalQuantity: 0,
      combinedSubtotal: 0,
      combinedTotalQuantity: 0,
      totalPrice: 0,
      taxDescription: '',
      metadata: {
        feesApplicable: null,
        feeType: null,
        fees: null,
      },
    },
    orderServicesState: {},
  };
  return initState;
};

export default rootReducer;
