import { format } from 'date-fns';
import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { getTimeZoneAbbr } from '../../_lib/lib';

type dateFormatterContext = (date: any, dateFormat: dateFormats) => string | null;

const initialDataFormatter = (date: any, dateFormat: dateFormats) => null;

const DateFromatterSetterContext = createContext<Dispatch<SetStateAction<any>> | null>(null);
const DateFormatterContext = createContext<dateFormatterContext>(initialDataFormatter);

type dateFormats =
  | 'FULL_DATETIME'
  | 'SHORT_DATE'
  | 'DATE_WITH_DAY'
  | 'DATETIME_WITH_TZ'
  | 'SHORT_NUM_DATE'
  | 'DATE_TIME'
  | 'COUNTDOWN'
  | 'SHORT_DATE_STRING';

export const standardPattern: Record<dateFormats, string> = {
  FULL_DATETIME: 'EEE MMM dd yyyy, hh:mm:ss a',
  DATE_WITH_DAY: 'E MMM dd yyyy',
  SHORT_DATE: 'MMM dd yyyy',
  DATETIME_WITH_TZ: 'MMM dd yyyy hh:mm a [@]',
  SHORT_NUM_DATE: 'MM/dd/yyyy',
  DATE_TIME: 'MMM dd yyyy hh:mm a',
  SHORT_DATE_STRING: 'MMM dd yyyy',
  COUNTDOWN: 'COUNTDOWN',
};

function DateFormatWrapper({ children }: any) {
  const [customDateFormats, setCustomDateFormats] = useState<any>({});

  const formatDate = (date: any, dateFormat: dateFormats) => {
    let formatTo = standardPattern[dateFormat];

    const customFormat = customDateFormats && customDateFormats[dateFormat];

    if (customFormat) {
      formatTo = customFormat;
    }

    try {
      const dateObj = new Date(date);
      const formattedDate = formatTo ? format(new Date(date), formatTo) : null;
      if (dateFormat === 'DATETIME_WITH_TZ') {
        return formattedDate?.replace('[@]', getTimeZoneAbbr(dateObj)) ?? null;
      }
      return formattedDate ?? null;
    } catch (error) {
      console.error(`Failed to format date: ${error.message}`);
      return null;
    }
  };

  return (
    <DateFromatterSetterContext.Provider value={setCustomDateFormats}>
      <DateFormatterContext.Provider value={formatDate}>{children}</DateFormatterContext.Provider>
    </DateFromatterSetterContext.Provider>
  );
}

export const useDateFormatter = () => {
  return useContext(DateFormatterContext);
};

export const useSetDateFormatter = () => {
  return useContext(DateFromatterSetterContext);
};

export default DateFormatWrapper;
