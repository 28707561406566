import * as types from './actionTypes';
import API from '@aws-amplify/api-rest';
import { SideMenuMap } from '../../types/AuthorityTypes';
import { IPOEServerResponse } from '../../types/POETypes';
import { unauthorizedUserErrorHandler } from '../../hooks/useDataService';
import apiToUrlMap from '../../ApiMapping';
import { logOutTheUser } from '../../_lib/util';

const apiName = 'tenantSpecificEndpoint';

export function userSet(user: any) {
  return {
    type: types.USER_SET,
    user,
  };
}

export function userStateSet(state: any) {
  return { type: types.USER_STATE_SET, state };
}

export function userAuthoritySet(authorities: any) {
  return { type: types.USER_AUTHORITIES_SET, authorities };
}

export const closePOEDialog = () => {
  return { type: types.CLOSE_POE_DIALOG };
};

export const openPOEDialog = () => {
  return { type: types.OPEN_POE_DIALOG };
};

export const setPOEData = (payload: IPOEServerResponse) => {
  return { type: types.SET_POE_DATA, payload };
};

const dispatchUserSet = (
  dispatch: (params: any) => void,
  username: string,
  isRoleBuyer: boolean,
  requiresAccountSetup: boolean,
  gracePeriodBuyer: boolean
) => {
  dispatch(
    userSet({
      username,
      isRoleBuyer,
      requiresAccountSetup,
      gracePeriodBuyer,
    })
  );
};

// export const setUserAndFetchAuthorities = (user: any) => {
//   return async (dispatch: any) => {
//     const userInfo: IUserInfo = await API.get(apiName, apiToUrlMap.getUserInfo, {});
//     console.log('user info', userInfo);
//     const requiresAccountSetup = !user.signInUserSession.idToken.payload['cognito:groups'];
//     const isRoleBuyer =
//       user.signInUserSession.idToken.payload['cognito:groups']?.indexOf('Buyer') > -1;

//     if (!isRoleBuyer || requiresAccountSetup)
//       return dispatchUserSet(dispatch, user.username, isRoleBuyer, requiresAccountSetup);

//     API.get(apiName, '/px-api-gateway/security/authorities', {
//       headers: {},
//     })
//       .then(async (res: any) => {
//         dispatchUserSet(dispatch, user.username, isRoleBuyer, requiresAccountSetup);
//         const authorityObj: any = {};
//         const sideMenuItemsObj: any = {};

//         res.forEach((obj: any) => {
//           authorityObj[obj['authority']] = true;
//           if (SideMenuMap[obj['authority']])
//             sideMenuItemsObj[SideMenuMap[obj['authority']]] = 'show';
//         });

//         localStorage.setItem('PxUserAuthorities', JSON.stringify(authorityObj));
//         localStorage.setItem('PxSideMenuItems', JSON.stringify(sideMenuItemsObj));

//         dispatch(userAuthoritySet(authorityObj));
//         dispatch(sideMenuItemsSet(sideMenuItemsObj));
//       })
//       .catch(async (error) => {
//         if (error.response?.data?.statusCode === 403)
//           try {
//             return await unauthorizedUserErrorHandler(error.response?.data);
//           } catch (error) {
//             return;
//           }
//         logOutTheUser(error.response.data.message, 'error');
//       });
//   };
// };

export const setUserInfoState = (dispatch: (params: any) => void, userInfo: any) => {
  userInfo.firstName = userInfo.firstName?.trim();
  userInfo.lastName = userInfo.lastName?.trim();
  if (Array.isArray(userInfo.enabledTags)) {
    userInfo.enabledTags = userInfo.enabledTags.reduce(
      (acc: Record<string, boolean>, tag: string) => {
        acc[tag] = true;
        return acc;
      },
      {}
    );
  }
  dispatch(userStateSet({ userInfo }));
};

export const setUserAndFetchAuthorities = (
  user: any,
  loginFlowScreen?: boolean,
  I18n?: any,
  waitAndRefetch?: boolean
) => {
  return async (dispatch: any) => {
    try {
      const userInfo = await API.get(apiName, apiToUrlMap.getUserInfo, {});
      const requiresAccountSetup =
        !user.signInUserSession.idToken.payload['cognito:groups'] && !userInfo.hasTemporaryAccess;
      const isRoleBuyer =
        user.signInUserSession.idToken.payload['cognito:groups']?.indexOf('Buyer') > -1 ||
        userInfo.hasTemporaryAccess;
      const gracePeriodBuyer = userInfo.buyerType === 'VERIFIED_PROSPECT_IN_GRACE_PERIOD';

      if (!isRoleBuyer || requiresAccountSetup || gracePeriodBuyer)
        return dispatchUserSet(
          dispatch,
          user.username,
          isRoleBuyer,
          requiresAccountSetup,
          gracePeriodBuyer
        );

      if (loginFlowScreen) {
        localStorage.setItem('pxFetchBroadcastBanner', 'true');
        localStorage.setItem('pxSpecialPriceSnackBar', 'true');
        if (!userInfo.hasTemporaryAccess)
          localStorage.setItem('pxMissingDocumentsSnackbar', 'true');
        else localStorage.setItem('pxProspectInviteExpirationSnackbar', 'true');
      }
      const res = await API.get(apiName, '/px-api-gateway/security/authorities', { headers: {} });
      dispatchUserSet(dispatch, user.username, isRoleBuyer, requiresAccountSetup, gracePeriodBuyer);
      //user info set
      setUserInfoState(dispatch, userInfo);
      const authorityObj: any = {};
      const sideMenuItemsObj: any = {};

      res.forEach((obj: any) => {
        authorityObj[obj['authority']] = true;
        if (SideMenuMap[obj['authority']]) sideMenuItemsObj[SideMenuMap[obj['authority']]] = 'show';
      });

      localStorage.setItem('PxUserAuthorities', JSON.stringify(authorityObj));
      localStorage.setItem('PxSideMenuItems', JSON.stringify(sideMenuItemsObj));

      dispatch(userAuthoritySet(authorityObj));
      // dispatch(sideMenuItemsSet(sideMenuItemsObj));
    } catch (error: any) {
      if (error.response?.data?.statusCode === 403)
        try {
          return await unauthorizedUserErrorHandler(error.response?.data, I18n);
        } catch (error) {
          return;
        }
      // wait for 3 sec and refetch user info
      if (waitAndRefetch) {
        console.log('refetching user info');
        return await new Promise((resolve) =>
          setTimeout(
            () => resolve(dispatch(setUserAndFetchAuthorities(user, loginFlowScreen))),
            3000
          )
        );
      }
      logOutTheUser(error.response.data.message, 'error');
    }
  };
};
