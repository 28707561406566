import apiToUrlMap from '../../ApiMapping';
import { useGetDataService } from './useDataService';
import { checkNullOrUndefined } from '../../_lib/lib';
import { useGetQueryCacheData } from './useGetQueryCacheData';
import { isEmpty } from '../../_lib/util';
import { IFormattedCountry, IFormattedState } from '../../types/IAccountSetupDetails';

export const useCountryAndStateCodes = (isShowCountryAndStateValues: boolean) => {
  const countryCodesCached = useGetQueryCacheData(apiToUrlMap.getCountry);
  const stateCodesCached = useGetQueryCacheData(apiToUrlMap.getStates);

  const { data: countryCodes, isLoading: isCountryLoading } = useGetDataService<IFormattedCountry>({
    url: apiToUrlMap.getCountry,
    enabled: isShowCountryAndStateValues && checkNullOrUndefined(countryCodesCached),
    staleTime: Infinity,
    queryParams: {
      transformData: (response) => {
        if (isEmpty(response)) return null;

        const countryCodesObj: IFormattedCountry = {};

        response.forEach(
          (country: { countryName: string; countryCode: string }) =>
            (countryCodesObj[country.countryCode] = country.countryName)
        );

        return countryCodesObj;
      },
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const { data: stateCodes, isLoading: isStateLoading } = useGetDataService<IFormattedState>({
    url: apiToUrlMap.getStates,
    enabled: isShowCountryAndStateValues && checkNullOrUndefined(stateCodesCached),
    staleTime: Infinity,
    queryParams: {
      transformData: (response) => {
        if (isEmpty(response)) return null;

        const stateCodesObj: IFormattedState = {};
        const stateCodes: {
          [countryCode: string]: { stateCode: string; stateName: string }[];
        } = response;

        for (const countryCode in stateCodes) {
          stateCodesObj[countryCode] = stateCodes[countryCode].reduce(
            (acc, { stateCode, stateName }) => {
              acc[stateCode] = stateName;
              return acc;
            },
            {} as { [stateCode: string]: string }
          );
        }

        return stateCodesObj;
      },
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return {
    countryCodes,
    stateCodes,
    isCountryLoading,
    isStateLoading,
  };
};
