import React, { forwardRef } from 'react';
import Box from '@material-ui/core/Box';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from '../ReviewStockImport';
import { offerStatusChipMap } from '../../../types/enumToLabelsMap';
import { I18nContext } from '../../../I18n';
import { dataFormatting, getAttrLabel } from '../../../_lib/lib';
import { useMutationService } from '../../../hooks/useQueryCustomHooks/useDataService';
import apiToUrlMap, { formatString } from '../../../ApiMapping';
import { IStockImportData } from '../ValidRequestBox/ValidRequestBox';

export default forwardRef((props: any, ref: any) => {
  const I18n = React.useContext(I18nContext);

  const classes = useStyles();

  const {
    data,
    setOverwriteItems,
    stockListSettings,
    warehousesObj,
    curTenantSettings,
    warehouseGroupAcceptsOffers,
  } = props;

  const { mutateAsync: deleteOfferImportMuate } = useMutationService({
    onSuccess: (_, props) => {
      const itemNumberWarehouse = props.variables;
      setOverwriteItems(
        data.filter((item: any) => item.itemNumberWarehouse !== itemNumberWarehouse)
      );
    },
  });

  const onDeleteOverwriteItem = async (itemNumberWarehouse: string) => {
    try {
      await deleteOfferImportMuate({
        url: formatString(apiToUrlMap.stockListImportDelete, {
          itemNumberWarehouse: btoa(itemNumberWarehouse),
        }),
        httpMethod: 'DELETE',
        variables: itemNumberWarehouse,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div id="overwrite-request" className="cell small-12" ref={ref}>
      <Box className="cell small-12 px-review-box px-box" boxShadow={2}>
        <div className="grid-x margin-bottom-1">
          <div className="cell small-12">
            <InfoIcon
              className={`margin-right-1 vertical-middle px-review-icons ${classes.warning}`}
            />
            <Typography className="inline-block" variant="h5">
              {I18n._format(
                I18n.xReqOverwriteItemsCart ||
                  'There are ${1} requests that will overwrite items in the cart:',
                [data.length]
              )}
            </Typography>
          </div>
        </div>
        <div className="grid-x">
          <div className="cell small-3 text-muted grid-x align-middle">{I18n.item || 'Item'}</div>
          <div className="cell small-2 text-center text-muted grid-x align-middle align-center text-center uppercase">
            {I18n.sku || getAttrLabel(curTenantSettings, 'item_number', 'SKU')}
          </div>
          <div className="cell small-1 text-center text-muted grid-x align-middle align-center text-center capitalize">
            {I18n.warehouse || getAttrLabel(curTenantSettings, 'warehouse', 'Warehouse')}
          </div>
          {warehouseGroupAcceptsOffers && (
            <div className="cell small-1 text-center text-muted grid-x align-middle align-center text-center capitalize">
              {I18n.oldStatus || 'Old Status'}
            </div>
          )}
          <div className="cell small-1 text-center text-muted grid-x align-middle align-center text-center capitalize">
            {warehouseGroupAcceptsOffers
              ? I18n.oldQty || 'Old Quantity'
              : I18n.quantity || 'Quantity'}
          </div>
          {warehouseGroupAcceptsOffers && (
            <div className="cell small-1 text-center text-muted grid-x align-middle align-center text-center capitalize">
              {I18n.oldPrice || 'Old Price'}
            </div>
          )}
          <div className="cell small-1 text-center text-muted grid-x align-middle align-center text-center capitalize">
            {I18n.newQuantity || 'New Quantity'}
          </div>
          {warehouseGroupAcceptsOffers && (
            <div className="cell small-1 text-center text-muted grid-x align-middle align-center text-center capitalize">
              {I18n.newPrice || 'New Price'}
              {/*br*/}
            </div>
          )}
        </div>
        {data
          .sort((item1: any, item2: any) =>
            item1.existingOfferStatusString.localeCompare(item2.existingOfferStatusString)
          )
          .map((item: IStockImportData, index: number) => {
            const warehouse =
              stockListSettings?.['warehouseLabels']?.[item.warehouse] ?? item.warehouse;
            return (
              <div key={item.itemNumberWarehouse} className="grid-x margin-top-1">
                <div className="cell small-3 grid-x align-middle align-left">
                  <label className="wrap-word-any">{item.description}</label>
                </div>
                <div className="cell text-center small-2 grid-x align-middle align-center text-center">
                  <label className="wrap-word-any">{item.itemNumber}</label>
                </div>
                <div className="cell text-center small-1 grid-x align-middle align-center text-center">
                  <label className="wrap-word-space">{warehouse}</label>
                </div>
                {warehouseGroupAcceptsOffers && (
                  <div
                    className={`cell text-center small-1 grid-x align-middle align-center text-center`}
                  >
                    <Chip
                      label={item.existingOfferStatusString}
                      size="small"
                      className={`${offerStatusChipMap[item.existingOfferStatus]} px-filter-on`}
                    />
                  </div>
                )}
                <div className="cell text-center small-1 grid-x align-middle align-center text-center">
                  <label className="wrap-word-any">
                    {item.existingTransactionQuantity > 0 ? item.existingTransactionQuantity : 0}
                  </label>
                </div>
                {warehouseGroupAcceptsOffers && (
                  <div className="cell text-center small-1 grid-x align-middle align-center text-center">
                    <label className="wrap-word-any">
                      {dataFormatting('currency-with-no-decimal', item.existingTransactionPrice)}
                    </label>
                  </div>
                )}
                <div className="cell text-center small-1 grid-x align-middle align-center text-center">
                  <label className="wrap-word-any">{item.offerQuantity}</label>
                </div>
                {warehouseGroupAcceptsOffers && (
                  <div className="cell text-center small-1 grid-x align-middle align-center text-center">
                    <label className="wrap-word-any">
                      {dataFormatting('currency-with-no-decimal', item.offerPrice)}
                    </label>
                  </div>
                )}
                <div className="cell auto grid-x align-right align-middle">
                  <IconButton onClick={() => onDeleteOverwriteItem(item.itemNumberWarehouse)}>
                    <CancelIcon />
                  </IconButton>
                </div>
              </div>
            );
          })}
      </Box>
    </div>
  );
});
