import { useQueryClient } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import apiToUrlMap from '../../ApiMapping';
import { setUserInfoState, userAuthoritySet, userSet } from '../../redux/actions/userActions';
import { SideMenuMap } from '../../types/AuthorityTypes';
import { logOutTheUser } from '../../_lib/util';
import { unauthorizedUserErrorHandler } from '../useDataService';
import { useGetDataService } from './useDataService';
import { publicApiMapping } from './usePublicQueryStates';

const initialSideMenuState = {
  Home: 'show',
  StockList: 'show',
  Auctions: 'hide',
  Orders: 'hide',
  Preorders: 'hide',
  Returns: 'hide',
  Cart: 'hide',
};

const dispatchUserSet = (
  dispatch: (params: any) => void,
  username: string,
  isRoleBuyer: boolean,
  requiresAccountSetup: boolean,
  gracePeriodBuyer: boolean
) => {
  dispatch(
    userSet({
      username,
      isRoleBuyer,
      requiresAccountSetup,
      gracePeriodBuyer,
    })
  );
};
export const useGetUserInfoAndAuthorities = (isLoginFlow?: boolean) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { data: userState, refetch: fetchUserInfo } = useGetDataService<any>({
    url: apiToUrlMap.getUserInfo,
    queryParams: {
      transformData: async (userInfo: any) => {
        let user = await Auth.currentAuthenticatedUser();
        const requiresAccountSetup =
          !user.signInUserSession.idToken.payload['cognito:groups'] && !userInfo.hasTemporaryAccess;
        const isRoleBuyer =
          user.signInUserSession.idToken.payload['cognito:groups']?.indexOf('Buyer') > -1 ||
          userInfo.hasTemporaryAccess;
        const gracePeriodBuyer = userInfo.buyerType === 'VERIFIED_PROSPECT_IN_GRACE_PERIOD';

        dispatchUserSet(
          dispatch,
          user.username,
          isRoleBuyer,
          requiresAccountSetup,
          gracePeriodBuyer
        );
        const isUserSignedIn = isRoleBuyer && !requiresAccountSetup && !gracePeriodBuyer;

        if (isLoginFlow && isUserSignedIn) {
          localStorage.setItem('pxFetchBroadcastBanner', 'true');
          localStorage.setItem('pxSpecialPriceSnackBar', 'true');
          if (!userInfo.hasTemporaryAccess)
            localStorage.setItem('pxMissingDocumentsSnackbar', 'true');
          else localStorage.setItem('pxProspectInviteExpirationSnackbar', 'true');
        }

        //user info set
        setUserInfoState(dispatch, userInfo);
        return {
          userInfo,
          userName: user.username,
          isRoleBuyer,
          requiresAccountSetup,
          gracePeriodBuyer,
          isSignedIn: isUserSignedIn,
          pass: '*',
        };
      },
    },
    enabled: false,
    staleTime: Infinity,
    // wait for 3 sec and refetch user info
    refetchInterval: 3000,
    // onSuccess: (userInfo: any) => {
    //   if (userInfo.isSignedIn) {
    //     localStorage.setItem('pxFetchBroadcastBanner', 'true');
    //     localStorage.setItem('pxSpecialPriceSnackBar', 'true');
    //     if (!userInfo.hasTemporaryAccess)
    //       localStorage.setItem('pxMissingDocumentsSnackbar', 'true');
    //     else localStorage.setItem('pxProspectInviteExpirationSnackbar', 'true');
    //   }
    // },
    onError: async (error: any) => {
      if (error.status === 403)
        try {
          const i18n = queryClient.getQueryData([publicApiMapping.i18n]);
          return await unauthorizedUserErrorHandler(error.errorBody, i18n);
        } catch (error) {
          return;
        }
      logOutTheUser(error.message, 'error');
    },
  });

  const { data: authorities } = useGetDataService({
    url: '/px-api-gateway/security/authorities',
    enabled: !!userState?.isSignedIn,
    staleTime: Infinity,
    initialData: {
      data: undefined,
      authorityObj: {},
      sideMenuState: initialSideMenuState,
    },
    initialDataUpdatedAt: 0,
    queryParams: {
      transformData: (authorities) => {
        const authorityObj: any = {};
        const sideMenuItemsObj: { [key: string]: string } = {};
        authorities.forEach((obj: any) => {
          authorityObj[obj['authority']] = true;
          if (SideMenuMap[obj['authority']])
            sideMenuItemsObj[SideMenuMap[obj['authority']]] = 'show';
        });

        return {
          data: authorities,
          authorityObj,
          sideMenuState: { ...initialSideMenuState, ...sideMenuItemsObj },
        };
      },
    },
    onSuccess: (data: any) => {
      const { authorityObj, sideMenuState: sideMenuItemsObj } = data;

      localStorage.setItem('PxUserAuthorities', JSON.stringify(authorityObj));
      localStorage.setItem('PxSideMenuItems', JSON.stringify(sideMenuItemsObj));

      dispatch(userAuthoritySet(authorityObj));
    },
    onError: async (error: any) => {
      if (error.status === 403)
        try {
          const i18n = queryClient.getQueryData([publicApiMapping.i18n]);
          return await unauthorizedUserErrorHandler(error.errorBody, i18n);
        } catch (error) {
          return;
        }
      logOutTheUser(error.message, 'error');
    },
  });

  return { userState, fetchUserInfo, authorities };
};
