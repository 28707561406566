import { Auth } from '@aws-amplify/auth';
import * as constants from '../types/enumToLabelsMap';
import { IShippingUI, IShippingValidationPair } from '../types/IBillingShippingTypes';
import { format, intervalToDuration } from 'date-fns';

export const formatExpiryUTCString = (acceptedOrCounteredExpirationDate: any, I18n?: any) => {
  if (!acceptedOrCounteredExpirationDate) return null;
  const diffDate = intervalToDuration({
    start: new Date(acceptedOrCounteredExpirationDate),
    end: new Date(),
  });
  const { days, hours, minutes } = diffDate;

  return `${days ? `${days}${I18n?.COUNTDOWN_DAYS || 'd'} ` : ''} ${
    hours ? `${hours}${I18n?.COUNTDOWN_HOURS || 'h'}` : ''
  } ${minutes ? `${minutes}${I18n?.COUNTDOWN_MIN || 'm'} ` : ''}`.trim();
};

export const offerStatusExtraInfoValues: any = (
  offerStatus: any,
  computedOfferPrice: any,
  minimumCheckoutQuantity: any,
  acceptedOrCounteredExpirationDate: any,
  counterPrice: any
) => {
  switch (offerStatus) {
    case constants.ACCEPTED:
      return [
        computedOfferPrice,
        minimumCheckoutQuantity,
        formatExpiryUTCString(acceptedOrCounteredExpirationDate),
      ];
    case constants.PRICE_DROPPED:
      return [];
    case constants.COUNTERED:
      return [
        computedOfferPrice,
        counterPrice,
        minimumCheckoutQuantity,
        formatExpiryUTCString(acceptedOrCounteredExpirationDate),
      ];
    case constants.NOT_ACCEPTED:
    case constants.EXPIRED:
      return [computedOfferPrice];
    default:
      return [];
  }
};

export const downloadFile = async (file: any, fileName?: string) => {
  const name = file.headers['content-disposition']
    ? file.headers['content-disposition'].split('=')[1].trim()
    : fileName;
  const href = window.URL.createObjectURL(file.data);
  const link = document.createElement('a');
  link.href = href;
  link.style.display = 'none';
  link.setAttribute('download', name); //or any other extension
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(href);
  return true;
};

export const getAttrLabel = (settings: Record<string, any>, attr: string, defaultStr: string) => {
  const labels: Record<string, string> = settings?.attributeLabels;
  if (labels && attr && labels.hasOwnProperty(attr) && labels[attr]) return labels[attr];
  return defaultStr;
};

const reN = /[^0-9]/g;
const reA = /[^a-zA-Z]/g;

const sortAlphaNum = (a: any, b: any, sort: 'asc' | 'desc') => {
  let aA = a.replace(reA, '');
  let bA = b.replace(reA, '');
  if (aA === bA) {
    let aN = parseInt(a.replace(reN, ''), 10);
    let bN = parseInt(b.replace(reN, ''), 10);
    return aN === bN ? 0 : aN > bN ? (sort === 'asc' ? 1 : -1) : sort === 'asc' ? -1 : 1;
  } else {
    return aA > bA ? (sort === 'asc' ? 1 : -1) : sort === 'asc' ? -1 : +1;
  }
};

const sortOperation = (a: any, b: any, sort: 'asc' | 'desc') => {
  return sort === 'asc' ? b - a : a - b;
};
interface ISortByType {
  type: string;
  b: any;
  a: any;
  sort: 'asc' | 'desc';
}

export const sortByType = ({ type, b, a, sort }: ISortByType): number => {
  let res = 0;
  switch (type) {
    case 'id':
      res = sortAlphaNum(b, a, sort);
      break;
    case 'date':
      res = sortOperation(+new Date(a), +new Date(b), sort);
      break;
    case 'percent':
      res = sortOperation(+a, +b, sort);
      break;
    case 'currency':
      res = sortOperation(+a, +b, sort);
      break;
    case 'integer':
      res = sortOperation(+a, +b, sort);
      break;
    default:
      res = sort === 'asc' ? `${b}`.localeCompare(`${a}`) : `${a}`.localeCompare(`${b}`);
      break;
  }
  return res;
};

interface ISearchFilter {
  searchTerm: string;
  itemsList: Array<any>;
  settings: any;
}

// checking the index of query
const indexOf = (itemDesc: string, q: string) => {
  let result = false;
  const parts = q.split(' ');
  const count = parts.length;
  for (let i = 0; i < count; i++) {
    if (itemDesc.indexOf(parts[i]) > -1) {
      result = true;
      break;
    }
  }
  return result;
};

export const searchFilter = ({ searchTerm, itemsList, settings }: ISearchFilter) => {
  let pieces: Array<any> = [];
  // dividing search term
  if (searchTerm && searchTerm.length) {
    // @Todo - Make / dynamic to come from config
    const toBePieces = searchTerm.replace(/\//gi, '+').split('+');
    for (let i = 0; i < toBePieces.length; i++) {
      const pieceTerm = toBePieces[i].trim().toLowerCase();
      if (pieceTerm) {
        pieces.push(pieceTerm);
      }
    }
  }

  const results: Array<any> = [];

  itemsList.forEach((item: any) => {
    // is a match for the search term
    let matchSearchTerm = false;
    if (pieces.length) {
      // creating description to search in
      let description = JSON.stringify(Object.values(item)).toLowerCase();
      if (
        item.warehouse &&
        settings['warehouseLabels'] &&
        settings['warehouseLabels'][item.warehouse]
      ) {
        description = settings['warehouseLabels'][item.warehouse].toLowerCase() + description;
      }
      // if(date)
      // counting the degree of match
      let countApprovals = 0;
      pieces.forEach((term: string) => {
        if (indexOf(description, term)) {
          countApprovals += 1;
        }
      });
      if (pieces.length === countApprovals) {
        matchSearchTerm = true;
      }
    }
    if (!searchTerm || !searchTerm.length) {
      matchSearchTerm = true;
    }
    // If item survives filtering, add it to final list
    if (matchSearchTerm) {
      results.push({ ...item });
    }
  });

  return results;
};

const getPieces = (searchTerm: string) => {
  let pieces: Array<any> = [];
  // dividing search term
  if (searchTerm && searchTerm.length) {
    // @Todo - Make / dynamic to come from config
    const toBePieces = searchTerm.replace(/\//gi, '+').split('+');
    for (let i = 0; i < toBePieces.length; i++) {
      const pieceTerm = toBePieces[i].trim().toLowerCase();
      if (pieceTerm) {
        pieces.push(pieceTerm);
      }
    }
  }
  return pieces;
}

export const searchEngine = (searchTerm: string, item: Record<string, any>, settings: any) => {
  const pieces: Array<string> = getPieces(searchTerm);
  let matchSearchTerm = false;
  if (pieces.length) {
    // creating description to search in
    let description = JSON.stringify(Object.values(item)).toLowerCase();
    if (
      item.warehouse &&
      settings['warehouseLabels'] &&
      settings['warehouseLabels'][item.warehouse]
    ) {
      description = settings['warehouseLabels'][item.warehouse].toLowerCase() + description;
    }

    // counting the degree of match
    let countApprovals = 0;
    pieces.forEach((term: string) => {
      if (indexOf(description, term)) {
        countApprovals += 1;
      }
    });
    if (pieces.length === countApprovals) {
      matchSearchTerm = true;
    }
  }
  if (!searchTerm || !searchTerm.length) {
    matchSearchTerm = true;
  }

  // If item survives filtering, add it to final list
  return matchSearchTerm;
}

// field validation
export const fieldValidation = (value: string, type: string, I18n?: any) => {
  let errorValRes = '';

  switch (type) {
    case 'alphanumeric':
      if (!value.match(/^([0-9]|[a-z])+([0-9a-z]+)$/i)) {
        errorValRes =
          I18n?.pleaseEnterAlphaNumericValueOfBuyer || 'Please enter alpha numeric value of buyer';
      }
      break;
    case 'atleast-one-alphabet':
      if (!value.match(/^[a-z]+[a-z0-9]*/i)) {
        errorValRes =
          I18n?.pleaseStartWithAtleastOneAlphabet || 'Please start with atleast one alphabet';
      }
      break;
    case 'number':
      if (!value.match(/^[0-9]+$/i)) {
        errorValRes = I18n?.invalidNumber || 'Invalid number';
      }
      break;
    case 'integer':
      if (!value.match(/^[0-9]+$/i)) {
        errorValRes = I18n?.invalidNumber || 'Invalid number';
      }
      break;
    case 'currency':
      if (!value.match(/^[0-9]+$/i)) {
        errorValRes = I18n?.invalidCurrencyValue || 'Invalid currency value';
      }
      break;
    case 'internationalized-zip':
      // eslint-disable-next-line no-useless-escape
      if (
        value.match(/(^[-]+[\S \n]*[-]*$)|(^[-]*[\S \n]*[-]+$)/gi) ||
        // eslint-disable-next-line no-useless-escape
        !value.match(/^[a-z0-9\-]+[\s]?[a-z0-9\-]*$/i)
      ) {
        errorValRes = I18n?.invalidZip || 'Invalid zip';
      }
      break;
    case 'required':
      if (!value) {
        errorValRes = I18n?.requiredField || 'Required field';
      }
      break;
    case 'email':
      // eslint-disable-next-line no-useless-escape
      if (
        !/^(?:(?!\.{2,}).)*$/.test(value) ||
        !/^([\p{L}\p{M}\p{N}+\-#$!%&_])([\p{L}\p{M}\p{N}.+\-#$!%&_]){0,99}@([\p{L}\p{M}\p{N}.+\-#$!%&_]{1,253}\.){1,8}[a-zA-Z]{2,63}$/u.test(
          value
        )
      ) {
        errorValRes = I18n?.invalidEmail || 'invalid email';
      }
      break;
    case 'tel': {
      // eslint-disable-next-line no-useless-escape
      if (!value.match(/^[+]?[0-9/() .\-\/]{10,15}/g) || value.match(/[a-z]/g)) {
        // telephone validation
        errorValRes = I18n?.invalidTelephoneNumber || 'invalid telephone number';
      }
      break;
    }
    // atleast on alphabet and other can be numbers => country/state/city
    // numbers with special characters => zip
    case 'text':
      break;
  }
  return errorValRes;
};

const specialSymbols: any = {
  [Infinity]: '∞',
};

export const checkNullOrUndefined = (val: any) => {
  return val === null || val === undefined;
};

export type DataFormattingType =
  | 'id'
  | 'percent'
  | 'percent-with-2-decimals'
  | 'currency'
  | 'currency-with-no-decimal'
  | 'integer'
  | 'string'
  | 'number';

export const dataFormatting = (
  type: DataFormattingType,
  value: any,
  isZeroShownForCurrency?: boolean
) => {
  switch (type) {
    case 'id':
      return value || value === 0 ? `${value}` : '--';
    case 'percent':
      return percentFormatting(value, 1);
    case 'percent-with-2-decimals':
      return percentFormatting(value, 2);
    case 'currency':
      const currencyPrecision = localStorage.getItem('PxCurrencyPrecision');
      const decimalPointLimit: number = currencyPrecision ? +currencyPrecision : 2;
      return currencyFormatter(value, decimalPointLimit, isZeroShownForCurrency);
    case 'currency-with-no-decimal':
      return currencyFormatter(value, 0, isZeroShownForCurrency);
    case 'integer':
      return integerFormatting(value);
    case 'number':
      return value
        ? value.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2 })
        : '--';
    default:
      return value ? specialSymbols[value] || value : '--';
  }
};

const percentFormatting = (value: any, decimalDig: number) => {
  if (value === Infinity) return specialSymbols[value];
  if (typeof value === 'string') return `${value}%`;
  return +value ? `${(+value).toFixed(decimalDig)}%` : value && value !== 'NaN' ? value : '--';
};

const integerFormatting = (value: any) => {
  return `${
    value ? value.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 0 }) : '--'
  }`;
};

const currencyFormatter = (value: any, minimumFractionDigits: number, isZeroShown?: boolean) => {
  if ((!isZeroShown || (isZeroShown && value !== 0)) && !value) return '--';
  if (value === Infinity) return specialSymbols[value];
  const localeCurrency = localStorage.getItem('PxCurrencySymbol');
  let currencySymbol = '$';
  if (localeCurrency) {
    currencySymbol = localeCurrency;
  }

  if (typeof value === 'string') {
    return `${currencySymbol}${value}`;
  }
  const val = +(+value).toFixed(minimumFractionDigits);
  return val >= 0
    ? `${currencySymbol}${val.toLocaleString('en', {
        useGrouping: true,
        minimumFractionDigits,
      })}`
    : `-${currencySymbol}${Math.abs(val).toLocaleString('en', {
        useGrouping: true,
        minimumFractionDigits,
      })}`;
};

export const formatToFloatLocaleStringCurrency = (num: number) => {
  if (!num) return '--';
  const localeCurrency = localStorage.getItem('PxCurrencySymbol');
  let currencySymbol = '$';
  if (localeCurrency) currencySymbol = localeCurrency;
  const currencyPrecision = localStorage.getItem('PxCurrencyPrecision');
  const decimalPointLimit: number = currencyPrecision ? +currencyPrecision : 2;
  return `${currencySymbol}${num.toLocaleString(undefined, {
    minimumFractionDigits: decimalPointLimit,
    maximumFractionDigits: decimalPointLimit,
  })}`;
};

export const restrictCurrencyDecimals = (value: string) => {
  const decimalPoints = value.toString().split('.')[1]?.length ?? 0;
  const currencyPrecision = localStorage.getItem('PxCurrencyPrecision');
  if (currencyPrecision && decimalPoints > parseInt(currencyPrecision)) return true;
  if (currencyPrecision === '0' && value.includes('.')) return true;
  return false;
};

export const generateBillingShippingUIData = (
  I18n: any,
  additionalServices: Array<any>
): Array<IShippingUI> => {
  const uiData: Array<IShippingUI> = [
    {
      title: I18n.shipAddress || 'Shipping Address',
      optionId: 'shippingAddress',
      payloadKey: 'shippingAddresses',
      dependentPayloadKey: 'shippingMethods',
      selectOptionsHeadingLabel:
        I18n.pleaseSelectShippingAddress || 'Please Select Shipping Address',
    },
    {
      title: I18n.shipMethod || 'Shipping Method',
      optionId: 'shippingMethod',
      payloadKey: 'shippingMethods',
      selectOptionsHeadingLabel: I18n.pleaseSelectShippingMethod || 'Please Select Shipping Method',
    },
    {
      title: I18n.billingAddress || 'Billing Address',
      optionId: 'billingAddress',
      payloadKey: 'billingAddresses',
      dependentPayloadKey: 'paymentMethods',
      selectOptionsHeadingLabel: I18n.pleaseSelectBillingAddress || 'Please Select Billing Address',
    },
    {
      title: I18n.payMethod || 'Payment Method',
      optionId: 'paymentMethod',
      payloadKey: 'paymentMethods',
      selectOptionsHeadingLabel: I18n.pleaseSelectPaymentMethod || 'Please Select Payment Method',
    },
  ];

  if (additionalServices && additionalServices?.length > 0)
    uiData.push({
      title: I18n.additionalServices || 'Additional Services',
      optionId: 'additionalServices',
      payloadKey: 'additionalServices',
      selectOptionsHeadingLabel: I18n.chooseAdditionalServices || 'Choose Additional Services',
    });

  return uiData;
};

// order details rules
export const isBuyerSite = true;

export const generateShippingValidationMsg = (I18n: any, id: Array<string>) => {
  const label: Array<string> = [];
  id.forEach((val) => {
    switch (val) {
      case 'shippingMethod':
        label.push(I18n.shipMethod || 'Shipping Method');
        break;
      case 'paymentMethod':
        label.push(I18n.payMethod || 'Payment Method');
        break;
      case 'shippingAddress':
        label.push(I18n.shipAddress || 'Shipping Address');
        break;
      case 'billingAddress':
        label.push(I18n.billingAddress || 'Billing Address');
        break;
      case 'additionalServices':
        label.push(I18n.additionalService || 'Additional Services');
        break;
    }
  });
  return label.join(', ');
};

export const getAnalyticsData = (
  lineItems: Array<any>,
  phonexOrderNumber: string,
  cartTotal: number,
  warehouseCode: string
) => {
  return {
    lineItems,
    phonexOrderNumber,
    cartTotal,
    warehouseCode,
  };
};

export const shippingValidationPairs: IShippingValidationPair = {
  shippingAddress: 'shippingMethod',
  billingAddress: 'paymentMethod',
};

export const getTimeZoneAbbr = (date: Date | number) => {
  // convert date to string, and grab last group with () brackets, which has timezone info.
  const groups = Array.from(date.toString().matchAll(/\((.*?)\)/g));
  const timeZoneInfo = groups[groups.length - 1][1];
  // construct timezone abbrevation
  const timeZoneInfoShort = timeZoneInfo
    .split(' ')
    .map((part) => part.charAt(0).toUpperCase())
    .join('');
  return timeZoneInfoShort;
};

export const formatDateWithTimeZone = (date: string) => {
  const dateObj = new Date(date);
  const shortTimeZone = getTimeZoneAbbr(dateObj);
  const formattedDate = format(dateObj, 'MMM dd, yyy, hh:mm aaa');
  return `${formattedDate} ${shortTimeZone}`;
};

export const logOutWithoutMessageAndRedirectToLogin = async () => {
  try {
    await Auth.signOut();
    localStorage.clear();
    window.indexedDB.deleteDatabase('d2');
    document.location.href = '/';
  } catch (error: any) {
    console.error('error signing out: ', error);
  }
};

export const showAuctions = (authorities: any) =>
  authorities.CAN_VIEW_AUCTION || authorities.CAN_BID_IN_AUCTION;

// Sanitize String
// eslint-disable-next-line no-useless-escape
const illegalRe = /[\/\?<>\\:\*\|":®©℗]/g;
// eslint-disable-next-line no-control-regex
const controlRe = /[\x00-\x1f\x80-\x9f]/g;
export function sanitizeString(input: string, replacement: string = '_') {
  return input.replace(illegalRe, replacement).replace(controlRe, replacement);
}
