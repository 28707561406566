export const CONFIG_SET = 'CONFIG_SET';
export const DEACTIVATE_TENANT = 'DEACTIVATE_TENANT';
export const SYSTEM_MESSAGE_SET = 'SYSTEM_MESSAGE_SET';
export const NETWORK_SYSTEM_MESSAGE_SET = 'NETWORK_SYSTEM_MESSAGE_SET';
export const USER_SET = 'USER_SET';
export const SET_POE_DATA = 'SET_POE_DATA';
export const OPEN_POE_DIALOG = 'OPEN_POE_DIALOG';
export const CLOSE_POE_DIALOG = 'CLOSE_POE_DIALOG';
export const STOCK_SET = 'STOCK_SET';
export const URL_CHANGE = 'URL_CHANGE';
export const URL_PREVENT_WINDOW_CLOSE = 'URL_PREVENT_WINDOW_CLOSE';
export const URL_PREVENT_ROUTE_CHANGE = 'URL_PREVENT_ROUTE_CHANGE';
export const URL_PREVENT_LOGOUT = 'URL_PREVENT_LOGOUT';
export const FLAGS_SET = 'FLAGS_SET';
export const USER_STATE_SET = 'USER_STATE_SET';
export const USER_AUTHORITIES_SET = 'USER_AUTHORITIES_SET';
export const STOCKLIST_SETTINGS_SET = 'STOCKLIST_SETTINGS_SET';
export const PXN_WAREHOUSES_SET = 'PXN_WAREHOUSES_SET';
export const STOCKLIST_OFFERS_SET = 'STOCKLIST_OFFERS_SET';
export const STOCKLIST_OFFERS_UPDATE = 'STOCKLIST_OFFERS_SET';
export const STOCKLIST_OFFERS_ITEM_UPDATE = 'STOCKLIST_OFFERS_ITEM_UPDATE';
export const STOCK_ALERT_SET = 'STOCK_ALERT_SET';
export const STOCK_ALERT_UPDATE = 'STOCK_ALERT_UPDATE';
export const CHECKOUT_PAYLOAD_SET = 'CHECKOUT_PAYLOAD_SET';
export const CHECKOUT_UPDATE_ORDER_NUMBER = 'CHECKOUT_UPDATE_ORDER_NUMBER';
export const CHECKOUT_UPDATE_REALTIME_METHOD_LABEL = 'CHECKOUT_UPDATE_REALTIME_METHOD_LABEL';
export const CHECKOUT_UPDATE_OTHER_SHIPPING_METHOD = 'CHECKOUT_UPDATE_OTHER_SHIPPING_METHOD';
export const ORDER_COMPUTE_FACTORS_UPDATE = 'ORDER_COMPUTE_FACTORS_UPDATE';
export const CHECKOUT_HOLD_ORDER_UNTIL_SHIPPING_QUOTE_UPDATE =
  'CHECKOUT_HOLD_ORDER_UNTIL_SHIPPING_QUOTE_UPDATE';
export const CHECKOUT_PAYMENT_INSTRUCTIONS_UPDATE = 'CHECKOUT_PAYMENT_INSTRUCTIONS_UPDATE';
export const ADD_TO_EXISTING_ORDER_PAYLOAD_SET = 'ADD_TO_EXISTING_ORDER_PAYLOAD_SET';
export const UPDATE_LINE_ITEM_PRICES = 'UPDATE_LINE_ITEM_PRICES';
export const REMOVE_LINE_ITEMS_EXCEPT = 'REMOVE_LINE_ITEMS_EXCEPT';
export const REMOVE_LINE_ITEMS_EXCEPT_BY_OFFERID = 'REMOVE_LINE_ITEMS_EXCEPT_BY_OFFERID';

//GA Tracking
export const PURCHASE_MADE = 'PURCHASE_MADE';
export const VIEW_ITEM_LIST = 'VIEW_ITEM_LIST';
export const VIEW_CART = 'VIEW_CART';
export const VIEW_ITEM = 'VIEW_ITEM';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const BEGIN_CHECKOUT = 'BEGIN_CHECKOUT';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';

export const NOTIFICATION_EVENT = 'NOTIFICATION_EVENT';
export const OUTBID_EVENT = 'OUTBID_EVENT';
