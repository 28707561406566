import React, { useEffect } from 'react';
import apiToUrlMap from '../../ApiMapping';
import { IPOEServerResponse, IPOEReduxState } from '../../types/POETypes';
import { useGetDataService } from './useDataService';
import { getPendingProofDocuments } from '../../_lib/util';

interface IUseGetProofOfExport {
  onError?: (error: any) => void;
  onSuccess?: (poeData: IPOEReduxState) => void;
  enabled?: boolean;
  fetchCondition?: (poeData?: IPOEReduxState) => boolean;
}

function useGetProofOfExport({
  onError,
  onSuccess,
  enabled,
  fetchCondition,
}: IUseGetProofOfExport) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { data: proofOfExport, refetch: fetchProofOfExport } = useGetDataService<IPOEReduxState>({
    url: apiToUrlMap.getProofOfExportPendingDocuments,
    queryParams: {
      transformData: async (poeServerResponse: IPOEServerResponse) => {
        const poeDocuments = getPendingProofDocuments(poeServerResponse.documents);
        // open dialog based on poe data
        const poeData: IPOEReduxState = {
          ...poeServerResponse,
          documents: poeDocuments,
        };
        return poeData;
      },
    },
    enabled: Boolean(enabled),
    staleTime: Infinity,
    onSuccess: (poeData: any) => {
      setIsOpen(poeData.documents.length > 0);
      onSuccess && onSuccess(poeData as IPOEReduxState);
    },
    onError: async (error: any) => {
      console.error('error in fetching proof of export', error);
      if (error.status === 403) return;
      onError && onError(error);
    },
  });

  useEffect(() => {
    fetchCondition && fetchCondition(proofOfExport) && fetchProofOfExport && fetchProofOfExport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { proofOfExport, fetchProofOfExport, isOpen, setIsOpen };
}

export default useGetProofOfExport;
